export default {
  breakpoints: [
    "480px",
    "600px",
    "768px",
    "1000px",
    "1200px",
    "1400px",
    "1600px",
    "1880px",
  ],
  colors: {
    action: "#b94d5d",
    background: "#fff",
    detail: "#dce0e8",
    mute: "#6b7c9f",
    open: "#bb573b",
    pending: "#b77706",
    solved: "#008040",
    suspect: "#c70039",
    text: "#4f5d78",
    textInvert: "#ffffff",
  },
  fonts: {
    body: "Gotham A, Gotham B, Verdana",
  },
  fontSizes: [
    16,
    "calc(1.5vw + 1.5vh + 1vmin)",
    "calc(3vw + 3vh + 2.5vmin)",
    "calc(1.1vw + 1.1vh + 1.1vmin)",
    "calc(1.1vw + 1vh + 1vmin)",
    "calc(1vw + 1vh + 1vmin)",
    "calc(14px + 0.75vh + 0.75vmin)",
  ],
  fontWeights: [400, 800, 900],
  lineHeights: {
    body: 1.5,
    heading: 1.125,
    single: 1,
  },
  shadows: ["0 1px 2px rgba(0,0,0,0.1)"],
  space: [
    0,
    2,
    4,
    6,
    8,
    10,
    12,
    14,
    16,
    18,
    20,
    22,
    24,
    26,
    28,
    30,
    32,
    34,
    36,
    38,
    40,
    42,
    44,
    46,
    48,
    50,
    52,
    54,
    56,
    58,
    60,
    62,
    64,
    66,
    68,
    70,
    72,
    74,
    76,
    78,
    80,
    82,
    84,
    86,
    88,
    90,
    92,
    94,
    96,
    98,
    100,
    102,
    104,
    106,
    108,
    110,
    112,
    114,
    116,
    118,
    120,
    122,
    124,
    126,
    128,
    130,
    132,
    134,
    136,
    138,
    140,
    142,
    144,
    146,
    148,
    150,
    152,
    154,
    156,
    158,
    160,
    162,
    164,
    166,
    168,
    170,
    172,
    174,
    176,
    178,
    180,
    182,
    184,
    186,
    188,
    190,
    192,
    194,
    196,
    198,
    200,
  ],
  styles: {
    root: {
      color: "text",
      fontFamily: "body",
      fontSize: ["16px", "16px", "16px", "18px"],
      fontWeight: 0,
      lineHeight: "body",
    },
    h1: {
      fontSize: 1,
      fontWeight: 2,
      lineHeight: 1.25,
      marginBottom: 0,
    },
    h2: {
      fontSize: 2,
      fontWeight: 2,
      lineHeight: "single",
      marginBottom: 0,
    },
    h3: {
      fontSize: 3,
      fontWeight: 1,
      lineHeight: "heading",
      marginBottom: 0,
    },
    h4: {
      fontSize: 4,
      fontWeight: 1,
      lineHeight: "heading",
    },
    h5: {
      fontSize: 5,
      fontWeight: 1,
      lineHeight: "heading",
    },
    h6: {
      fontSize: 6,
      fontWeight: 1,
      lineHeight: "heading",
    },
    strong: {
      fontWeight: 1,
    },
    a: {
      color: "action",
      fontWeight: 1,
      transition: "opacity 0.3s",
      "&:hover": {
        opacity: 0.8,
      },
    },
    table: {
      borderCollapse: "separate",
      borderSpacing: 0,
    },
  },
}
