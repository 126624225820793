// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-case-jsx": () => import("./../src/templates/case.jsx" /* webpackChunkName: "component---src-templates-case-jsx" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cases-js": () => import("./../src/pages/cases.js" /* webpackChunkName: "component---src-pages-cases-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-victims-js": () => import("./../src/pages/victims.js" /* webpackChunkName: "component---src-pages-victims-js" */),
  "component---src-pages-about-mdx": () => import("./../src/pages/about.mdx" /* webpackChunkName: "component---src-pages-about-mdx" */)
}

